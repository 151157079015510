import React, { useState, useEffect } from 'react';
import TopNavbar from './../../components/TopNavbar'
import 'bootstrap/dist/css/bootstrap.min.css';
import { getCondominio, getUsers, getAreas, destroyArea, createUser, createArea, uploadRegulamento, destroyUser, destroyRegulamento, destroySubArea } from '../../services/api';
import CreateUserItem from '../../components/CreateUserItem';
import SubAreasModal from '../../components/SubAreasModal';
import Moment from 'react-moment';
import moment from 'moment';
import 'moment/locale/pt-br';

import './condCpanel.css'
import { Link, useParams } from 'react-router-dom';
import Table from 'react-bootstrap/Table'
import CreateAreaItem from '../../components/CreateAreaItem';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import PdfUploadModal from '../../components/PdfUploadModal';

function CondCpanel() {

    moment().locale('pt-br')

    const { id } = useParams()

    const [Condominio, setCondominio] = useState('')
    const [Users, setUsers] = useState([])
    const [Areas, setAreas] = useState([])

    const [loading, setLoading] = useState(true);
    const [loadingError, setLoadingError] = useState(false);

    const handleNewUser = async (name, cpf, email, cargo, password, role) => {
        try {
            await createUser(name, cpf, email, cargo, password, id, role)
            console.log('New user', name, cpf, email, cargo, password, id, role)
            loadData()
        } catch (err) {
            console.error(err)
        }

    }


    const handleNewArea = async (name) => {
        try {
            await createArea(name, id)
            console.log('New area', name, id)
            loadData()
        } catch (err) {
            console.error(err)
        }

    }

    const [showDeleteUserModal, setShowDeleteUserModal] = useState(false);
    const [userToDelete, setUserToDelete] = useState(null);


    const handleDeleteUserConfirm = async () => {
        try {
            await destroyUser(userToDelete.condId, userToDelete._id);
            setShowDeleteUserModal(false);
            loadData()
        } catch (err) {
            console.error(err)
        }
    }

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [areaToDelete, setAreaToDelete] = useState(null);

    const handleDeleteConfirm = async () => {
        try {
            await destroyArea(areaToDelete.condId, areaToDelete._id);
            setShowDeleteModal(false);
            loadData()
        } catch (err) {
            console.error(err)
        }
    }

    const handleUploadRegulamento = async (file) => {
        await uploadRegulamento(id, file);
        loadData()
    }

    const handleDeleteRegulamento = async (regulamentoId) => {
        await destroyRegulamento(id, regulamentoId);
        loadData()
    }

    const handleDeleteSubArea = async (areaId, subAreaName, subAreaId) => {
        await destroySubArea(id, areaId, subAreaName, subAreaId);
        loadData()
    }

    const [selectedArea, setSelectedArea] = useState(null);
    const [showSubAreasModal, setShowSubAreasModal] = useState(false);

    const handleOpenSubAreasModal = () => {
        setShowSubAreasModal(true);
    };

    const handleCloseSubAreasModal = () => {
        setShowSubAreasModal(false);
    };


    const loadData = async () => {
        try {
            setLoading(true)
            const responseCondominio = await getCondominio(id)
            const responseUsers = await getUsers(id)
            const responseAreas = await getAreas(id)


            setCondominio(responseCondominio.data)
            setUsers(responseUsers.data)
            setAreas(responseAreas.data)
            setLoading(false)
        } catch (err) {
            console.error(err);
            setLoadingError(true)
        }
    }




    useEffect(() => {
        (async () => await loadData())()
    }, [])


    if (loadingError) {
        return (
            <div className='loading'>
                Erro ao carregar itens. <Link to={`/cpanel/`} >Voltar</Link>
            </div>
        )
    }

    if (loading) {
        return (
            <div className="loading">
                carregando...
            </div>
        )
    }

    return (
        <div className="user-menu">
            <TopNavbar />
            <div className="user-menu-areas">
                <div className="container px-4 py-5" id="hanging-icons">
                    <h2 className="pb-2 border-bottom">{Condominio.name}</h2>
                    <div className='container cpanel-itens'>
                        <div className='row'>
                            <div className='condominio-date col'>
                                <div>
                                    <div>CNPJ: {Condominio.cnpj}</div>
                                    <div>E-mail: {Condominio.email}</div>
                                    <div>Endereço: {Condominio.endereco}</div>
                                    <div>Telefone: {Condominio.telefone}</div>
                                    <div>Nome do Responsavel: {Condominio.responsavel}</div>
                                    <div>
                                        Criado em: <Moment format='DD/MM/YYYY, h:mm:ss '>{Condominio.createdAt}</Moment>
                                    </div>
                                </div>
                            </div>
                            < PdfUploadModal OnUpload={handleUploadRegulamento} onDelete={handleDeleteRegulamento} />
                        </div>
                    </div>
                    <div className='container cpanel-itens'>
                        <div className='row'>
                            <h3 className="pb-2 border-bottom">Usuários</h3>
                            < CreateUserItem onNewUser={handleNewUser} />
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>Nome</th>
                                        <th>CPF</th>
                                        <th>Email</th>
                                        <th>Cargo</th>
                                        <th>receber emails</th>
                                        <th>Excluir</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Users.map((user) => (
                                        <tr>
                                            <td>{user.name}</td>
                                            <td>{user.cpf}</td>
                                            <td>{user.email}</td>
                                            <td>{user.cargo || '-'}</td>
                                            <td>{user.role}</td>
                                            <td>
                                                <Button
                                                    size="sm"
                                                    onClick={() => {
                                                        setUserToDelete(user);
                                                        setShowDeleteUserModal(true);
                                                    }}
                                                >
                                                    Excluir
                                                </Button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                        <div className='row'>
                            <h3 className="pb-2 border-bottom">Áreas</h3>
                            <CreateAreaItem onNewArea={handleNewArea} />
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>Nome da área</th>
                                        <th>Excluir</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Areas.map((area) => (
                                        <tr key={area._id}>
                                            <td>
                                                <Link
                                                    size="sm"
                                                    onClick={() => {
                                                        setSelectedArea(area)
                                                        handleOpenSubAreasModal(area)
                                                    }}
                                                >
                                                    {area.name}
                                                </Link>
                                            </td>
                                            <td>
                                                <Button
                                                    size="sm"
                                                    onClick={() => {
                                                        setAreaToDelete(area);
                                                        setShowDeleteModal(true);
                                                    }}
                                                >
                                                    Excluir
                                                </Button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                            <SubAreasModal
                                condId={id}
                                area={selectedArea ? selectedArea : null}
                                show={showSubAreasModal}
                                onClose={handleCloseSubAreasModal}
                                onDelete={handleDeleteSubArea}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <Modal show={showDeleteUserModal} onHide={() => setShowDeleteUserModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirmação de exclusão</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Deseja realmente excluir o usuário "{userToDelete?.name}"?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeleteUserModal(false)}>
                        Cancelar
                    </Button>
                    <Button onClick={handleDeleteUserConfirm}>
                        Excluir
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirmação de exclusão</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Deseja realmente excluir a área "{areaToDelete?.name}"?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
                        Cancelar
                    </Button>
                    <Button onClick={handleDeleteConfirm}>
                        Excluir
                    </Button>
                </Modal.Footer>
            </Modal>

        </div>
    );
}

export default CondCpanel; 
