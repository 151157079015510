import TopNavbar from './../../components/TopNavbar'
import 'bootstrap/dist/css/bootstrap.min.css';
import Logo from '../../assets/logo.png';
import './LoginPage.css'
import React, { useState, useContext } from 'react';
import { AuthContext } from '../../contexts/auth';
import { createSession } from '../../services/api'
import { Navigate } from 'react-router-dom';

function LoginPage() {

    const { authenticated, user, login } = useContext(AuthContext)
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    if (authenticated && user.role === 'admin') {
        return <Navigate to='/cpanel' replace />;
    }

    if (authenticated) {
        return <Navigate to={`/${user.condId}`} replace />;
    }

    const handleLogin = async (e) => {
        e.preventDefault()
        console.log('email: ' + email)
        console.log('password: ' + password)
        login(email, password)

        const response = await createSession(email, password)
        console.log('login', response.data)
    }



    return (
        <div className="login">
            <TopNavbar />
            <div className="form-area ">
                <div className="mask">
                    <div className="container col-xl-10 col-xxl-8 px-4 py-5 ">
                        <div className="row align-items-center g-lg-5 py-5">
                            <div className="col-md-10 mx-auto col-lg-5">
                                <form className="py-3 px-4  ">
                                    <div className="form-floating mb-3 logo-content">
                                        <img className="logo" src={Logo} alt="Logo" />
                                        <h3>Aquila Vistorias</h3>
                                        {/* <h3>Email: {JSON.stringify(user.email)}</h3> */}
                                    </div>
                                    <div className="form-floating mb-3">
                                        <input
                                            type="email"
                                            className="form-control"
                                            id="floatingInput"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)} />
                                        <label htmlFor="floatingInput">Login</label>
                                    </div>
                                    <div className="form-floating mb-3">
                                        <input
                                            type="password"
                                            className="form-control"
                                            id="floatingPassword"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)} />
                                        <label htmlFor="floatingPassword">Senha</label>
                                    </div>
                                    <button className="w-100 btn btn-lg btn-primary" onClick={handleLogin}>Entrar</button>
                                    <hr className="my-4" />
                                </form>
                            </div>
                            <div className="col-lg-5 text-center ">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default LoginPage;
