import './ItemCpanelCond.css'
import React, { useState, useEffect, useContext } from 'react'

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { AuthContext } from '../contexts/auth';


// const condId = "63cfef1490af8b72c706fe5f"
// const areaId = "63d17115a3b21f3c82cbbdde"


function ItemCpanelCond({ onNewCond }) {

    const { user } = useContext(AuthContext)

    const [newCondName, setNewCondName] = useState('')
    const [newCondCNPJ, setNewCondCNPJ] = useState('')
    const [newCondEmail, setNewCondEmail] = useState('')
    const [newCondEndereco, setNewCondEndereco] = useState('')
    const [newCondTelefone, setNewCondTelefone] = useState('')
    const [newCondResponsavel, setNewCondResponsavel] = useState('')


    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);




    return (

        <div className='row'>

            <Button variant="primary" className='btn' onClick={handleShow}>
                Adicionar um novo condominio
            </Button>



            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Adicionar novo condominio</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form method="post" enctype="multipart/form-data">
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Nome do Condominio:</Form.Label>
                            <Form.Control
                                type="text"
                                value={newCondName}
                                onChange={(e) => setNewCondName(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>CNPJ:</Form.Label>
                            <Form.Control
                                type="number"
                                value={newCondCNPJ}
                                onChange={(e) => setNewCondCNPJ(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>E-mail:</Form.Label>
                            <Form.Control
                                type="email"
                                value={newCondEmail}
                                onChange={(e) => setNewCondEmail(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Endereço:</Form.Label>
                            <Form.Control
                                type="text"
                                value={newCondEndereco}
                                onChange={(e) => setNewCondEndereco(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Telefone:</Form.Label>
                            <Form.Control
                                type="number"
                                value={newCondTelefone}
                                onChange={(e) => setNewCondTelefone(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Responsavel:</Form.Label>
                            <Form.Control
                                type="text"
                                value={newCondResponsavel}
                                onChange={(e) => setNewCondResponsavel(e.target.value)}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary"
                        onClick={() =>
                            onNewCond(newCondName, newCondCNPJ, newCondEmail, newCondEndereco, newCondTelefone, newCondResponsavel)}>
                        Adicionar
                    </Button>
                </Modal.Footer>
            </Modal>

        </div >




    );
}

export default ItemCpanelCond;