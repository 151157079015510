import React, { useEffect, useState } from 'react';
import { Button, Modal, Table } from 'react-bootstrap';
import { getSubAreas, createSubArea } from '../services/api';

const SubAreasModal = ({ condId, area, show, onClose, onDelete }) => {
    const [subAreas, setSubAreas] = useState([]);
    const [condDateId, setCondDateId] = useState('');
    const [areaDate, setAreaDate] = useState('');
    const [newSubAreaName, setNewSubAreaName] = useState('');


    useEffect(() => {
        if (show) {
            fetchSubAreas();
        }
    }, [show]);

    const fetchSubAreas = async () => {
        try {
            const response = await getSubAreas(condId, area._id);
            setSubAreas(response.data);
            setCondDateId(condId);
            setAreaDate(area);
        } catch (error) {
            console.error('Erro ao buscar subáreas:', error);
        }
    };

    const handleAddSubArea = async () => {
        try {
            await createSubArea(condDateId, areaDate._id, newSubAreaName)

            fetchSubAreas();

            setNewSubAreaName('');
        } catch (error) {
            console.error(error)
        }

    };



    return (
        <Modal show={show} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>Sub-Areas - {areaDate.name} </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='d-flex justfy-content-center align-itens-center pb-2'>
                    <input
                        type="text"
                        placeholder='digite a subarea'
                        value={newSubAreaName}
                        onChange={(e) => setNewSubAreaName(e.target.value)}
                    />
                    <Button classsName="" onClick={handleAddSubArea}>Adicionar</Button>
                </div>
                <Table bordered>
                    <thead>
                        <tr>
                            <th>Nome da sub-área</th>
                            <th>Excluir sub-área</th>
                        </tr>
                    </thead>
                    <tbody>
                        {subAreas.map((subArea) => (
                            <tr key={subArea._id}>
                                <td>{subArea.subAreaName}</td>
                                <td>
                                    <Button onClick={() => onDelete(areaDate._id, subArea.subAreaName, subArea._id)}>
                                        Excluir
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Modal.Body>
        </Modal>
    );
};

export default SubAreasModal;
