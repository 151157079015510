import './ItemVistoria.css'
import React, { useState } from 'react'

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';


function CreateUserItem({ onNewUser }) {


    const [name, setName] = useState('')
    const [cpf, setCpf] = useState('')
    const [email, setEmail] = useState('')
    const [cargo, setCargo] = useState('')
    const [password, setPassword] = useState('')
    const [showPassword, setShowPassword] = useState(false);

    const [radioData, setRadioData] = useState({
        value1: 'sim',
        value2: 'não',
        selectedRadioInput: 'não'

    });


    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleTogglePassword = () => {
        setShowPassword(!showPassword);
    };


    return (

        <div className='row'>

            <Button variant="primary" onClick={handleShow}>
                Criar novo Usuário
            </Button>



            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Criar novo usuário</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Nome do usuário:</Form.Label>
                            <Form.Control
                                type="text"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Email:</Form.Label>
                            <Form.Control
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>CPF:</Form.Label>
                            <Form.Control
                                type="number"
                                value={cpf}
                                onChange={(e) => setCpf(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Cargo:</Form.Label>
                            <Form.Control
                                type="text"
                                value={cargo}
                                onChange={(e) => setCargo(e.target.value)}
                            />
                        </Form.Group>
                        <p>Receber emails de alerta?</p>
                        <Form.Check
                            type="radio"
                            label="sim"
                            name="userType"
                            id="sim"
                            value={radioData.value1}
                            onChange={(e) => setRadioData({ ...radioData, selectedRadioInput: e.target.value })}
                        />
                        <Form.Check
                            type="radio"
                            label="não"
                            name="userType"
                            id="não"
                            defaultChecked={true}
                            value={radioData.value2}
                            onChange={(e) => setRadioData({ ...radioData, selectedRadioInput: e.target.value })}
                        />


                        <Form.Group controlId="formBasicPassword">
                            <Form.Label>Senha</Form.Label>
                            <Form.Control
                                className="input-password"
                                type={showPassword ? "text" : "password"}
                                placeholder="Digite sua senha"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <Link className='btn-password' variant="secondary" onClick={handleTogglePassword}>
                                {showPassword ? "Ocultar senha" : "Exibir senha"}
                            </Link>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        fechar
                    </Button>
                    <Button variant="primary"
                        onClick={() =>
                            onNewUser(name, cpf, email, cargo, password, radioData.selectedRadioInput)}>
                        Adicionar
                    </Button>
                </Modal.Footer>
            </Modal>

        </div>




    );
}

export default CreateUserItem;