import React, { useState, useContext, useEffect } from 'react';
import { AuthContext } from '../contexts/auth';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { getRegulamento } from '../services/api';
import { useParams } from 'react-router-dom';

function PdfUploadModal({ OnUpload, onDelete }) {

    const { id } = useParams()


    const [selectedFile, setSelectedFile] = useState(null);
    const [Regulamento, setRegulamento] = useState([]);


    const [show, setShow] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleDeleteShow = () => setShowDeleteModal(true);
    const handleDeleteClose = () => setShowDeleteModal(false);



    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
        console.log(selectedFile);
    };


    const loadData = async () => {
        try {
            const responseRegulamento = await getRegulamento(id)
            setRegulamento(responseRegulamento.data)
        } catch (err) {
            console.error(err);
        }
    }


    useEffect(() => {
        (async () => await loadData())()
    }, [])


    return (

        <div className='col'>
            {Regulamento.name ? (
                <div>
                    <p>{Regulamento.name}</p>
                    <Button variant="danger" style={{ background: "red" }} onClick={handleDeleteShow}>
                        Deletar regulamento atual
                    </Button>
                </div>
            ) : (
                <div>
                    <p>Sem regulamento cadastrado</p>
                    <Button variant="primary" onClick={handleShow}>
                        Cadastrar regulamento
                    </Button>
                </div>
            )}
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Regulamento interno</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3">
                        <label htmlFor="pdf-upload" className="form-label">
                            Selecione um arquivo PDF:
                        </label>
                        <input
                            name='regulamento'
                            type="file"
                            className="form-control"
                            id="pdf-upload"
                            accept="application/pdf"
                            onChange={handleFileChange}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancelar
                    </Button>
                    <Button variant="primary" onClick={() => {
                        OnUpload(selectedFile);
                        console.log(selectedFile);
                        handleClose();
                    }} disabled={!selectedFile}>
                        Enviar
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showDeleteModal} onHide={handleDeleteClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Excluir regulamento</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Tem certeza de que deseja excluir o regulamento atual?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleDeleteClose}>
                        Cancelar
                    </Button>
                    <Button variant="danger" onClick={() => {
                        onDelete(Regulamento._id);
                        handleDeleteClose();
                    }}>
                        Excluir
                    </Button>
                </Modal.Footer>
            </Modal>

        </div>
    );
}

export default PdfUploadModal;