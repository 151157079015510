import { useContext, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import './TopNavbar.css';
import Image from 'react-bootstrap/Image';
import Logo from '../assets/logo.png';
import { Link } from 'react-router-dom';
import { AuthContext } from '../contexts/auth';

function TopNavbar() {

  const { user, logout } = useContext(AuthContext);

  const handleLogout = () => {
    logout();
  };

  const renderLoginButton = () => {
    return user ? (
      <Link className="btn btn-primary btn-sm" onClick={handleLogout}>
        Sair
      </Link>
    ) : null;
  };

  return (
    <Navbar className="navbar" expand="lg">
      <Container>
        <Navbar.Brand className="brand" href="/">
          <Image src={Logo} className="logo img-fluid. max-width: 100%;e height: auto;" />
          Aquila Vistorias
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto ">
            {renderLoginButton()}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default TopNavbar;
