import React, { useState, useContext, useEffect } from "react";
import TopNavbar from "./../../components/TopNavbar";
import "bootstrap/dist/css/bootstrap.min.css";
import { getVistorias, getVistoria } from "../../services/api";
import { AuthContext } from "../../contexts/auth";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Moment from "react-moment";
import moment from "moment";
import "moment/locale/pt-br";

import "../../components/Loading.css";
import "./vistoriasPage.css";

import VistoriaModal from "../../components/VistoriaModal";
import Pagination from "../../components/Pagination";

function VistoriaPage() {
    moment().locale("pt-br");

    const { user } = useContext(AuthContext);

    const [loading, setLoading] = useState(true);
    const [loadingError, setLoadingError] = useState(false);

    const [vistorias, setVistorias] = useState([]);

    const [showVistoriaModal, setShowVistoriaModal] = useState(false);

    const [selectedVistoriaId, setSelectedVistoriaId] = useState(null);

    //for pagination
    const [selected, setSelected] = useState("1");
    const [total, setTotal] = useState("");

    const handleShowVistoriaModal = (id) => {
        setSelectedVistoriaId(id);
        setShowVistoriaModal(true);
    };
    const handleCloseVistoriaModal = () => {
        setShowVistoriaModal(false);
    };

    const loadData = async () => {
        try {
            setLoading(true);
            const responseItens = await getVistorias(
                user.condId,
                selected,
                "10"
            );
            setVistorias(responseItens.data.vistorias);
            console.log(responseItens.data);
            setTotal(responseItens.data.total);
            setLoading(false);
        } catch (err) {
            console.error(err);
            setLoadingError(true);
        }
    };

    // useEffect(() => {
    //     (async () => await loadData())();
    // }, []);

    useEffect(() => {
        loadData();
    }, [selected]);

    if (loadingError) {
        return (
            <div className="loading">
                Erro ao carregar vistorias anteriores.{" "}
                <Link to={`/${user.condId}/`}>Voltar</Link>
            </div>
        );
    }

    if (loading) {
        return <div className="loading">carregando...</div>;
    }

    return (
        <div className="user-menu">
            <TopNavbar />
            <div className="user-menu-areas">
                <div className="container px-4 py-5" id="hanging-icons">
                    <h2 className="pb-2 border-bottom">Vistorias anteriores</h2>
                    <div className="container cpanel-itens">
                        <div className="row">
                            {/* <Condominios /> */}

                            {vistorias
                                .sort(
                                    (a, b) =>
                                        new Date(b.createdAt) -
                                        new Date(a.createdAt)
                                )
                                .map((vistoria) => (
                                    <div className="anterior-vist">
                                        <div>
                                            <div>{vistoria.areaName}</div>
                                            <div>
                                                <Moment format="DD/MM/YYYY, h:mm:ss ">
                                                    {vistoria.createdAt}
                                                </Moment>
                                            </div>
                                        </div>
                                        <Button
                                            key={vistoria._id}
                                            onClick={() =>
                                                handleShowVistoriaModal(
                                                    vistoria._id
                                                )
                                            }
                                        >
                                            ver
                                        </Button>
                                    </div>
                                ))}

                            <Pagination
                                selected={selected}
                                total={total}
                                setSelected={setSelected}
                                numberForPage="10"
                            />

                            <VistoriaModal
                                idVistoria={selectedVistoriaId}
                                show={showVistoriaModal}
                                onClose={handleCloseVistoriaModal}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default VistoriaPage;
