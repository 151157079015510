import React, { useState, useEffect, useContext } from 'react';
import TopNavbar from './../../components/TopNavbar'
import 'bootstrap/dist/css/bootstrap.min.css';
import './UserMenuPage.css'
import '../../components/Loading.css';
import ItemMenu from '../../components/ItemMenu';
import { getAreas } from '../../services/api';
import { Link } from 'react-router-dom';

import { AuthContext } from '../../contexts/auth';


function UserMenuPage() {

    const { user, logout } = useContext(AuthContext)

    const [loading, setLoading] = useState(true);
    const [loadingError, setLoadingError] = useState(false);


    const [areas, setAreas] = useState([])
    const loadData = async () => {
        try {
            setLoading(true);
            const response = await getAreas(user.condId)
            setAreas(response.data)
            setLoading(false);
        } catch (err) {
            console.error(err);
            setLoadingError(true)
        }
    }

    useEffect(() => {
        (async () => await loadData())()
    }, [])

    if (loadingError) {
        return (
            <div className='loading'>
                Erro ao carregar itens da vistoria. <Link onClick={logout()} >Voltar</Link>
            </div>
        )
    }

    if (loading) {
        return (
            <div className="loading">
                carregando...
            </div>
        )
    }

    return (
        <div className="user-menu">
            <TopNavbar />
            <div className="user-menu-areas">
                <div className="container px-4 py-5" id="hanging-icons">
                    <h2 className="pb-2 border-bottom">Áreas</h2>
                    <ItemMenu
                        areas={areas}
                    />
                </div>
            </div>
        </div>
    );
}

export default UserMenuPage;
