import React, { useEffect, useState, useContext } from "react";
import { Button, Modal } from "react-bootstrap";
import { getVistoria } from "../services/api";
import { AuthContext } from "../contexts/auth";
import Image from "react-bootstrap/esm/Image";
import "./spinner.css";

const VistoriaModal = ({ idVistoria, show, onClose }) => {
    const { user } = useContext(AuthContext);

    const [data, setData] = useState({});
    const [itens, setItens] = useState([]);

    useEffect(() => {
        if (show) {
            fetchData();
        }
    }, [show]);

    const fetchData = async () => {
        try {
            const response = await getVistoria(user.condId, idVistoria);
            setData(response.data);
            setItens(response.data.itens);
        } catch (error) {
            console.error("Erro ao buscar vistorias:", error);
        }
    };

    if (!fetchData) {
        return (
            <>
                <div class="spinner"></div>
            </>
        );
    }

    // agrupar itens por área
    const groupedItems = itens.reduce((acc, item) => {
        if (!acc[item.subAreaName]) {
            acc[item.subAreaName] = [];
        }
        acc[item.subAreaName].push(item);
        return acc;
    }, {});

    if (groupedItems) {
        Object.keys(groupedItems).forEach((key) => {
            groupedItems[key].sort((a, b) => a.name.localeCompare(b.name));
        });
    }

    return (
        <Modal show={show} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>Vistoria</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <br />
                    <h4 className="pb-2 border-bottom">Dados da vistoria</h4>
                    <div>
                        <strong>Nome do morador:</strong> {data.nomeMorador}
                    </div>
                    <div>
                        <strong>Apartamento:</strong> {data.numeroApartamento}
                    </div>
                    <div>
                        <strong>Email do morador:</strong> {data.emailMorador}
                    </div>
                    <div>
                        <strong>Obsevação gerais:</strong> {data.observacoes}
                    </div>
                </div>
                <br />
                {Object.keys(groupedItems).map((area) => (
                    <div key={area}>
                        <h2 className="pb-1 pt-5 border-bottom">{area}</h2>
                        {groupedItems[area].map((item) => (
                            <div key={item._id}>
                                <div>
                                    <br />
                                    <h4 className="pb-1 pt-1">{item.name}</h4>
                                    <div>
                                        <strong>Área:</strong>{" "}
                                        {item.subAreaName}
                                    </div>
                                    <div>
                                        <strong>Confere:</strong> {item.confere}
                                    </div>
                                    <div>
                                        <strong>Imagem:</strong>
                                        {item.FileBase64 !==
                                            "imagem não anexada" &&
                                        item.FileBase64 !== null &&
                                        item.FileBase64 !== "" &&
                                        item.FileBase64 !== undefined ? (
                                            <Image
                                                src={`data:image/**;base64,${item.FileBase64}`}
                                                thumbnail
                                            />
                                        ) : typeof item.file == "string" &&
                                          item.file !== "" &&
                                          item.file !== undefined &&
                                          item.file !== null &&
                                          item.file !== "imagem não anexada" ? (
                                            item.file.includes("mp4") ||
                                            item.file.includes("wav") ||
                                            item.file.includes("mkv") ||
                                            item.file.includes("mov") ||
                                            item.file.includes("webm") ? (
                                                <video
                                                    controls
                                                    style={{ width: "100%" }}
                                                    src={item.file}
                                                ></video>
                                            ) : (
                                                <Image
                                                    src={item.file}
                                                    thumbnail
                                                />
                                            )
                                        ) : (
                                            <span>Imagem não anexada</span>
                                        )}
                                    </div>
                                    <div>
                                        <strong>Obsevação do item:</strong>{" "}
                                        {item.obs}
                                    </div>
                                    <div>
                                        <strong>Quantidade:</strong>{" "}
                                        {item.quantity} pçs.
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                ))}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>
                    fechar
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default VistoriaModal;
